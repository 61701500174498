
import barba from '@barba/core';
import Swiper from 'swiper';
import gsap from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";
import Splide from "@splidejs/splide";

gsap.registerPlugin(ScrollTrigger);

export default class{

	constructor(){

        barba.hooks.beforeOnce((data) => {
            this.heroSlider()
            this.strengthSlider()
            this.companySlider()
            this.beforeSlider()
            this.afterSlider()
        })
        barba.hooks.once((data) => {
            this.worksSlider()

        })
        barba.hooks.after((data) => {
            this.heroSlider()
            this.strengthSlider()
            this.companySlider()
            this.worksSlider()
            this.beforeSlider()
            this.afterSlider()
        });
        
    }
    
    heroSlider(){
        new Swiper ('#hero--slider',{
            loop: true,
            effect :'fade',
            speed:3000,
            allowTouchMove :false,
            simulateTouch:false,
            autoplay: {
                delay: 4000
            }
        })
    }

    strengthSlider(){
        new Swiper ('.p-index-strength__image',{
            // loop: true,
            // speed:600,
            // allowTouchMove :false,
            // simulateTouch:false,
            // autoplay: {
            //     delay: 4000
            // },
            // effect: 'fade',
            loop: true,
            speed:2000,
            allowTouchMove :false,
            simulateTouch:false,
            autoplay: {
                delay: 3000,
            },
        })
    }

    companySlider(){
        new Swiper ('.p-index-company__image',{
            allowTouchMove: false,
            freeMode: true,
            loop: true,
            loopedSlides: 6,
            slidesPerView: 2,
            spaceBetween: 15,
            speed: 8000,
            autoplay: {
                delay: 0,
            }
        })
    }

    beforeSlider(){
        const before_thumbnails = document.querySelectorAll('.before-slider-thumbnails a');
        const beforeSlider = new Swiper('.before-slider', {
            on: {
                transitionStart(){
                    var	self = this;
                    for(var i = 0; i <  before_thumbnails.length; i++){
                        before_thumbnails[i].classList.remove('active');
                        before_thumbnails[self.realIndex].classList.add('active');
                    }
                }
            },
            preloadImages: false,
            lazy: {
                loadPrevNext: true,
            },
            loop : true,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev"
            }
        });
        
        for(var i = 0; i <  before_thumbnails.length; i++){
            beforeThumbnailAddEvent( before_thumbnails[i],i);
        }
        function beforeThumbnailAddEvent(el,i){
            el.addEventListener('click',function(e){
                e.preventDefault();
                beforeSlider.slideTo(i+1);
            })
        }
    }
    afterSlider(){
        const blog_thumbnails = document.querySelectorAll('.after-slider-thumbnails a');
        const blogSlider = new Swiper('.after-slider', {
            on: {
                transitionStart(){
                    var	self = this;
                    for(var i = 0; i <  blog_thumbnails.length; i++){
                        blog_thumbnails[i].classList.remove('active');
                        blog_thumbnails[self.realIndex].classList.add('active');
                    }
                }
            },
            preloadImages: false,
            lazy: {
                loadPrevNext: true,
            },
            loop : true,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev"
            }
        });
        
        for(var i = 0; i <  blog_thumbnails.length; i++){
            blogThumbnailAddEvent( blog_thumbnails[i],i);
        }
        function blogThumbnailAddEvent(el,i){
            el.addEventListener('click',function(e){
                e.preventDefault();
                blogSlider.slideTo(i+1);
            })
        }
    }

    worksSlider(){

        const responsive = gsap.matchMedia();
        responsive.add("(min-width: 992px)", () => {
            const listWrapperEl = document.querySelector('.side-scroll-list-wrapper');
            const listEl = document.querySelector('.side-scroll-list');
            if(listEl){
                gsap.to(listEl, {
                    x: () => -(listEl.clientWidth - listWrapperEl.clientWidth),
                    ease: 'none',
                    scrollTrigger: {
                        trigger: '.js-fix-slider',
                        start: 'center center', // 要素の上端（top）が、ビューポートの上端（top）にきた時
                        end: () => `+=${listEl.clientWidth - listWrapperEl.clientWidth}`,
                        scrub: 3,
                        pin: true,
                        // markers: true,
                        anticipatePin: 1,
                        invalidateOnRefresh: true,
                    },
                });
            }
        });

        const worksSlider = document.querySelector('[data-works-slider]');
        if(worksSlider){
            new Splide( '[data-works-slider]',{
                pagination: false,
                arrows:false,
                mediaQuery: 'min',
                // gap: '30px',
                // padding: 25,
                fixedWidth: "280px",
                speed: 800,
                easing: "cubic-bezier(0.215, 0.61, 0.355, 1)",
                breakpoints: {
                    576 : {
                        // padding: 30,
                        fixedWidth: "330px",
                    },
                    992: {
                        destroy: true,
                    },
                }
            } ).mount();
        }

        




        // const mediaQuery = window.matchMedia('(max-width: 575px)');
        // const listener = (event) => {
        //     if (event.matches) {
        //         console.log('SP');
        //         new Swiper ('.p-index-works .swiper-container',{
        //             slidesPerView: 'auto',
        //             spaceBetween: 16,
        //             grabCursor: true,
        //         })
        //     } else {
        //         const responsive = gsap.matchMedia();
        //         responsive.add("(min-width: 576px)", () => {
        //             const listWrapperEl = document.querySelector('.side-scroll-list-wrapper');
        //             const listEl = document.querySelector('.side-scroll-list');
        //             if(listEl){
        //                 gsap.to(listEl, {
        //                     x: () => -(listEl.clientWidth - listWrapperEl.clientWidth),
        //                     ease: 'none',
        //                     scrollTrigger: {
        //                         trigger: '.js-fix-slider',
        //                         start: 'center center', // 要素の上端（top）が、ビューポートの上端（top）にきた時
        //                         end: () => `+=${listEl.clientWidth - listWrapperEl.clientWidth}`,
        //                         scrub: 3,
        //                         pin: true,
        //                         markers: true,
        //                         anticipatePin: 1,
        //                         invalidateOnRefresh: true,
        //                     },
        //                 });
        //             }
        //         });
        //     }
        // };
        // mediaQuery.addEventListener("change", listener);
        // listener(mediaQuery);
    }
}