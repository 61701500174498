import barba from '@barba/core';
import anime from 'animejs';
import gsap from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default class{

	constructor(){
        barba.hooks.once((data) => {
            this.init()
        })
        barba.hooks.after((data) => {
            this.init()
        });
    }
    init (){
        /* 一文字ずつアニメーション */
        /* テキストを1文字ずつ分割 */
        const strs = document.querySelectorAll("[data-title-animation]")
        if(strs.length > 0){
            strs.forEach((el)=>{
                let pr_str = el.textContent.split("");
                let str = "";
                pr_str.map( s => {
                    str += "<span class='str__animation'>" + s  + "</span>";
                })
                
                el.innerHTML = str;
            })
        }

        /* テキストアニメーション */
        const titles = document.querySelectorAll("[data-title-animation]")
        if(titles.length > 0){
            titles.forEach( title => {
                const t = title.querySelectorAll('.str__animation')
                gsap.to(t, {
                    y: 0,
                    scale: 1,
                    stagger: 0.02,
                    opacity: 1,
                    scrollTrigger: {
                        trigger: title,
                        start: 'top center',
                        // markers: true
                    }
                }); 
            })
        }

    }
}