

import anime from 'animejs';

export default class{
    /**
     * loading要素の初期設定等を行うコンストラクタ
     * DOM生成時に即時実行
     */
    constructor() {
        this.mask = document.querySelector('#js-loading__mask')
        this.first = "#js-loading__mask .js-loading__first"
        this.second = "#js-loading__mask .js-loading__second"
        this.rollup = "#js-loading__mask .rollup-item"
        this.rollup02 = "#js-loading__mask .rollup-item02"
        this.logoAnimation_elm = document.querySelector('#logo-animation')
        if(this.mask){
            this.mask.style.display = "block"
        }

        if(process.env.MIX_DISP_LOADING == "false" && process.env.NODE_ENV == "development"){
            if(this.mask){
                this.mask.style.display = "none" 
            }
        }
    }
    /**
     * 初期表示前に1度だけ実行
     * @param {*} data 
     */
    beforeOnce(data){
        const self = this;
        return new Promise(resolve => {


            if(data.next.namespace == "not-loading"){
                resolve()
            }

            let timeline = anime.timeline();
            
            timeline
            .add({
                targets: '#logo-animation',
                opacity:[0,1],
                translateY: ["30%", 0],
                easing: 'easeOutQuart',
                duration : 1000,
                complete: () =>{
                    
                    resolve();
                }
            // 初期読み込み時の待ち時間
            })
        })
    }
    /**
     * 初期表示時に1度だけ実行
     * @param {*} data 
     */
    once(data){
        return new Promise(resolve => {
            resolve();
        })
    }
    /**
     * 初期表示完了後に1度だけ実行
     * @param {*} data 
     */
    afterOnce(data){

        return new Promise(resolve => {

            if(data.next.namespace == "not-loading"){
                resolve();
            }

            const timelime = anime.timeline()
            timelime
            .add({
                targets: this.logoAnimation_elm,
                // translateY: [ 0 ,"-200%"],
                opacity: [1,0],
                easing: 'easeOutQuart',
                duration : 1000,
                complete: () =>{
                    this.mask = document.querySelector('#js-loading__mask')
                    if(this.mask){
                        this.mask.style.display = "none"
                    }
                    resolve();
                }
            // 初期読み込み時の待ち時間
            },2000)
            .add({
                targets: this.rollup,
                translateY : [0, "-100%"],
                easing: 'easeInCubic',
                delay: function(el, i) {
                    return (i * 60);
                },
                duration : 600,
            },1500)
            const heroCatch = document.querySelector(".l-hero__image")
            if(heroCatch){
                setTimeout(function(){
                    let str_animation =  anime.timeline();
                    str_animation.add({
                        targets : '.main-img',
                        scale: [1.3,1],
                        easing: "easeOutExpo",
                        duration: 800
                    },1400)
                    // .add({
                    //     targets : '.cls-2',
                    //     translateX: ["0%","100%"],
                    //     easing: "easeInExpo",
                    //     duration:1000,
                    //     // opacity: [1, 0],
                    //     translateX: ["0%","100%"]
                    // },"-=800")
                    // .add({
                    //     targets : '.main-catch',
                    //     easing: "easeInExpo",
                    //     // opacity:[0,1],
                    //     duration:1000,
                    //     translateY: ["100%","0%"]
                    // },"-=800")
                    .add({
                        targets : '.text-box',
                        opacity:[0,1],
                        easing: "easeOutExpo",
                        delay: function(el, i) {
                            return (i * 100);
                        },
                    },"-=500")
                    .add({
                        targets : '.l-hero__bg',
                        opacity:[0,0.3],
                        easing: "easeOutExpo",
                        duration:3000
                    },"-=500")
                },600)
            }
            
        });
    }
    /**
     * 次ページ読み込み前に実行
     * @param {*} data 
     */
    beforeLeave(data){
        return new Promise(resolve => {
            resolve();
        })
    }
    /**
     * 次ページ読み込み時に実行
     * @param {*} data 
     */
    leave(data){

        return new Promise(resolve => {
            this.mask = document.querySelector('#js-loading__mask')
            if(this.mask){
                this.mask.style.display = "block"
            }
            
            if(data.next.namespace == "not-loading"){
                resolve();
            }

            data.next.container.style.opacity = 0;
            const timelime = anime.timeline()
            timelime
            
            .add({
                targets: this.first,
                translateY : ["100%",0],
                easing: 'easeInCubic',
                duration : 500,
                complete : () => {
                    resolve();
                }
            })

        });
    }
    /**
     * 次ページ読み込み完了後に実行
     * @param {*} data 
     */
    afterLeave(data){
        return new Promise(resolve => {
            resolve();
            // setTimeout(function(){
                // let str_animation =  anime.timeline();
                // str_animation.add({
                //     targets : '.main-img',
                //     scale: [1.3,1],
                //     easing: "easeOutExpo",
                //     duration: 800
                // },800)
                // .add({
                //     targets : '.l-hero__bg',
                //     opacity:[0,0.3],
                //     easing: "easeOutExpo",
                //     duration:5000
                // },"-=500")
            // },"-3000")
        })
    }
    /**
     * 次ページ表示前に実行
     * @param {*} data 
     */
    beforeEnter(data){
        return new Promise(resolve => {
            resolve();
        })
    }
    /**
     * 次ページ表示時に実行
     * @param {*} data 
     */
    enter(data){
        return new Promise(resolve => {
            resolve();
        })
    }
    /**
     * 次ページ表示完了後に実行
     * @param {*} data 
     */
    afterEnter(data){
        return new Promise(resolve => {
            resolve();
        })
    }
    /**
     * すべて完了後に実行
     * @param {*} data 
     */
    after(data){

        return new Promise(resolve => {


            if(data.next.namespace == "not-loading"){
                this.initialScroll ()
                data.next.container.style.opacity = 1;
                resolve();
            }


            const timelime = anime.timeline()
            timelime

            .add({
                targets: this.rollup,
                translateY : [0, "-100%"],
                easing: 'easeInCubic',
                delay: function(el, i) {
                    return (i * 60);
                },
                duration : 600,
                begin: () => {
                    this.initialScroll ()
                    data.next.container.style.opacity = 1;
                },
                complete : () => {

                    this.mask = document.querySelector('#js-loading__mask')
                    if(this.mask){
                        this.mask.style.display = "none"
                    }
                    resolve();
                }
            },200)
            .add({
                targets : '.main-img',
                scale: [1.3,1],
                easing: "easeOutExpo",
                duration: 800
            },800)
            .add({
                targets : '.text-box',
                opacity:[0,1],
                easing: "easeOutExpo",
                delay: function(el, i) {
                    return (i * 100);
                },
            },"-=500")
            .add({
                targets : '.l-hero__bg',
                opacity:[0,0.3],
                easing: "easeOutExpo",
                duration:3000
            },"-=500")

            
        });
    }
    initialScroll (){
        if( location.hash == "" ){
            window.scrollTo(0,0);
        }else{
            let digit = location.hash.replace(/^#/,"");
            let anchor = document.getElementById( digit );
            if ( !anchor ) return;
            
            if(anchor){
                const rect = anchor.getBoundingClientRect();
                const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
                let top = rect.top + scrollTop;
                const header = document.querySelector('[data-header]');
                if(header){
                    if(header){
                        top = top - header.clientHeight;
                    }
                }
                window.scrollTo(0,top);
            }
            
        }
    }
}