import barba from '@barba/core';
import gsap from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default class{

	constructor(){
        barba.hooks.afterOnce((data) => {
            this.keyVisual()
            // this.FixSlider()
            this.LineAnm()
        });
        barba.hooks.after((data) => {
            this.keyVisual()
            // this.FixSlider()
            this.LineAnm()
        });
    }
    LineAnm() {
        const line01 = document.querySelector(".bgline-01");
        const line02 = document.querySelector(".bgline-02");
        const line03 = document.querySelector(".bgline-03");
        const line04 = document.querySelector(".bgline-04");

        const line_anime = gsap.timeline({
            scrollTrigger: {
              trigger: ".p-index-about", //アニメーションが始まるトリガーとなる要素
              start: "top+=10% center",
            //   markers: true,
            }
        });
        line_anime.to(line01, { //アニメーションする要素
            width: "100%",
            duration: 0.3,
            opacity: 1,
        });
        line_anime.to(line02, { //アニメーションする要素
            width: "200%",
            duration: 0.3,
            opacity: 1,
        },"-=0.1");
        line_anime.to(line03, { //アニメーションする要素
            width: "124%",
            duration: 0.3,
            opacity: 1,
        },"-=0.1");
        line_anime.to(line04, { //アニメーションする要素
            width: "120%",
            duration: 0.3,
            opacity: 1,
        },"-=0.1");

        // const aboutline01 = document.querySelector(".aboutline-01");
        // const aboutline02 = document.querySelector(".aboutline-02");
        // const aboutline03 = document.querySelector(".aboutline-03");
        // const line_anime2 = gsap.timeline({
        //     scrollTrigger: {
        //       trigger: ".p-about-strength", //アニメーションが始まるトリガーとなる要素
        //       start: "top+=10% center",
        //     //   markers: true,
        //     }
        // });
        // line_anime2.to(aboutline01, { //アニメーションする要素
        //     width: "100%",
        //     duration: 0.5,
        //     opacity: 1,
        //     ease: "power4.out"
        // });
        // line_anime2.to(aboutline02, { //アニメーションする要素
        //     width: "300%",
        //     duration: 0.5,
        //     opacity: 1,
        //     ease: "power4.out"
        // },"-=0.3");
        // line_anime2.to(aboutline03, { //アニメーションする要素
        //     width: "100%",
        //     duration: 0.5,
        //     opacity: 1,
        //     ease: "power4.out"
        // },"-=0.3");
    }
    keyVisual(){
        // キービジュアルアニメーション
        let mm = gsap.matchMedia();
        

        

        mm.add("(max-width: 575px)", () => {
            const heroimg = document.querySelector(".l-hero__image");
            if(heroimg){
                gsap.to(heroimg, {
                    scrollTrigger: {
                        trigger: heroimg,
                        pin: true,
                        start: 'top 63px',
                        endTrigger: ".l-hero__wrapper",
                        end: 'bottom bottom',
                        // markers: true,
                    },
                });
            }
        });
        mm.add(" (min-width: 576px) and (max-width: 991px) ", () => {
            const heroimg = document.querySelector(".l-hero__image");
            if(heroimg){
                gsap.to(heroimg, {
                    scrollTrigger: {
                        trigger: heroimg,
                        pin: true,
                        start: 'top 80px',
                        endTrigger: ".l-hero__wrapper",
                        end: 'bottom bottom',
                        // markers: true,
                    },
                });
            }
        });
        mm.add("(min-width: 992px)", () => {
            const heroimg = document.querySelector(".l-hero__image");
            if(heroimg){
                gsap.to(heroimg, {
                    scrollTrigger: {
                        trigger: heroimg,
                        pin: true,
                        start: 'top 96px',
                        endTrigger: ".l-hero__wrapper",
                        end: 'bottom bottom',
                        // markers: true,
                    },
                });
            }
        });
        
    }
}